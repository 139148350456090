/* ===============================================================
                    
                    Global reset and css

================================================================== */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  // color: #ffff;
}

a,
a:hover {
  text-decoration: inherit !important;
  color: inherit !important;
}
button,
button:focus,
input:focus,
textarea:focus,
a:focus {
  outline: 0 !important;
  border: none;
}

.outline-0 {
  outline: 0;
}

.button:focus {
  outline: 0;
  box-shadow: 0 0 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// scrollbar chrome
*::-webkit-scrollbar {
  height: vw(15px);
  width: vw(5px);
}

*::-webkit-scrollbar-track,
*::-ms-scrollbar-track-color {
  margin: vw(10px);
  background: #d9d1f9;
  box-shadow: 0 0 vw(6px) rgba(0, 0, 0, 0.16);
  border-radius: vw(35px);
}

*::-webkit-scrollbar-thumb {
  border-radius: vw(32px);
  background: #4f8fbf;

  &:hover {
    background: #4f8fbf;
  }
}

html,
body {
  overflow-x: hidden !important;
  //background-color: rgba(243, 246, 251, 1) !important;
  //background-color: #455051;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
}

html {
  scroll-behavior: smooth;
  width: 100% !important;
  max-width: 100% !important;
}
