.product-div {
  background: #000000;
  box-shadow: 0 0 vw(41px) rgba(0, 0, 0, 0.16);
  text-align: center;
  margin-top: vw(50px);
  padding-top: vw(34px);
  padding-bottom: vw(99px);
}
.font-36-brandon-bold--product {
  color: rgba(255, 255, 255, 0.43);
  margin-bottom: 0;
}
.font-64-circular--product {
  color: white;
  margin-bottom: vw(27px);
}
.font-24-brandon-light--product {
  color: #8a878a;
  text-transform: capitalize;
}
/*==========================================================================================
                                    slider
============================================================================================*/
.product-slider-div {
  padding-top: vw(49px);
  padding-left: vw(80px);
}

.product-slider-card {
  width: vw(432px);
  height: auto;
  margin-right: vw(22px);
}

.product-slider-card-img-div {
  width: vw(420px);
  height: vw(464px);
  margin-bottom: vw(30px);
  img {
    width: 100%;
    height: 100%;
  }
}

.font-36-circular--product-slider-text {
  color: #e0e0e0;
  padding: 0 vw(5px) vw(6px) vw(5px);
  margin: auto;
  margin-bottom: vw(31px);
  width: fit-content;
  border-bottom: 1px solid #ffffff;
  //position: relative;

  //&:before {
  //  content: "";
  //  position: absolute;
  //  width: vw(200px);
  //  height: 1px;
  //  background: #ffffff;
  //  left: vw(100px);
  //  bottom: 0;
  //}
}
.font-24-brandon-bold--product-slider-text {
  color: white;
}
.product-slider-right-text {
  margin-left: vw(45px);
  ul {
    text-align: left;
    li {
      font-size: vw(24px);
      line-height: vw(30px);
      list-style: disc;
      color: #ffffff;
      padding-left: 0;
      padding-bottom: vw(11px);
    }
  }
}
