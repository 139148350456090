.footer-div {
  text-align: center;
  padding: vw(28px) vw(10px) vw(124px) vw(10px);
  background: rgba(67, 75, 96, 0.28);
}
.footer-logo-img {
  width: vw(266px);
  height: auto;
}
.footer-text {
  font-size: vw(24px);
  line-height: vw(30.36px);
  margin-bottom: 0;
  @include fontCircularStd;
  padding-right: vw(74px);
}
.footer-ul {
  display: inline-flex;
  justify-content: center;
  li {
    list-style: none;
    font-size: vw(24px);
    line-height: vw(30.36px);
    margin-bottom: 0;
    @include fontCircularStd;
    padding-right: vw(74px);

    &:nth-child(2) {
      padding-right: 0;
    }
  }
}
