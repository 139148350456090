.navbar-div {
  display: flex;
  //  align-items: center;
  justify-content: space-between;
  padding-left: vw(42px);
}
.innoventic-logo {
  width: vw(279px);
  height: auto;
  margin-top: vw(40px);
  margin-bottom: vw(60px);
}
.navbar-ul {
  display: flex;
  padding-top: vw(79px);
  li {
    list-style: none;
    padding-right: vw(122px);
    font-size: vw(24px);
    line-height: vw(30.36px);
    color: #c4c4c4;
    @include fontCircularStd;

    &:nth-child(3) {
      padding-right: vw(104px);
    }
  }
}
