.home-content-div {
    background: black;
    padding: vw(81px) vw(84px) vw(145px) vw(105px);
    width: fit-content;
}
.home-font-36-brandon-bold {
    color: #8a878a;
    margin-bottom: vw(17px);
}
.home-font-64-circular {
    color: white;
    margin-bottom: vw(17px);
}
.home-font-24-brandon-normal {
    color: #bcbcbc;
    margin-bottom: vw(25px);
}
.home-div {
    position: relative;
    background-image: url('../../../imgs/home/home-bg.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: vw(1224px) vw(853px);
    padding-bottom: vw(145px);
}
.bg-black {
    background: black;
    margin-top: vw(50px);
}
