.font-36-circular {
  font-size: vw(36px);
  line-height: vw(46px);
  @include fontCircularStd;
}

.font-64-circular {
  font-size: vw(64px);
  line-height: vw(80.96px);
  @include fontCircularStd;
}

.font-24-brandon-light {
  font-size: vw(24px);
  line-height: vw(34px);
  @include fontCreaMedium;
  //@include fontBrandonGrotesqueLight;
}
.font-19-brandon-normal {
  font-size: vw(19px);
  line-height: vw(27.17px);
  @include fontCreaMedium;
  //@include fontBrandonGrotesqueLight;
  margin-bottom: 0;
}
.font-24-brandon-bold {
  font-size: vw(24px);
  line-height: vw(34.32px);
  @include fontCeraBold;
  //@include fontBrandonGrotesqueBold;
  margin-bottom: 0;
}
.font-36-brandon-bold {
  font-size: vw(36px);
  line-height: vw(51.48px);
  @include fontCeraBold;
  //@include fontBrandonGrotesqueBold;
}
.font-24-crea-medium {
  font-size: vw(24px);
  line-height: vw(34px);
  @include fontCreaMedium;
}
