/* ===============================================================
                    
                            Mixins

================================================================== */
/* ===============================================================
                            circular
================================================================== */
@mixin fontCircularStd {
  font-weight: 450;
  font-family: "Circular", sans-serif, "Helvetica";
}

/* ===============================================================
                           Brandon Grotesque
================================================================== */
@mixin fontBrandonGrotesqueBold {
  font-weight: 700;
  font-family: "brandon-grotesque-bold", sans-serif, "Helvetica";
}
@mixin fontBrandonGrotesqueLight {
  font-weight: 300;
  font-family: "brandon-grotesque-light", sans-serif, "Helvetica";
}

/* ===============================================================
                           Crea
================================================================== */
@mixin fontCreaMedium {
  font-weight: 500;
  font-family: cera-medium, sans-serif, "Helvetica";
}
@mixin fontCeraBold {
  font-weight: 700;
  font-family: cera-bold, sans-serif, "Helvetica";
}
