.contact-div {
  padding: vw(112px) vw(107px) vw(68px) vw(121px);
}

.font-19-brandon-normal--contact {
  color: #262626;
}
.font-64-circular--contact {
  margin-bottom: vw(56px);
}
.contact-details-div {
  padding-top: vw(100px);
  white-space: nowrap;
}
.contact-location-img {
  width: vw(44px);
  margin-right: vw(45px);
}

.contact-call-img {
  width: vw(41.2px);
  margin-right: vw(42px);
}

.contact-mail-img {
  width: vw(50px);
  margin-right: vw(33px);
}

.contact-fax-img {
  width: vw(53px);
  margin-right: vw(36px);
}

.contact-add-img {
  width: vw(45px);
  margin-right: vw(40px);
}

.contact-form-div {
  width: vw(930px);
  background: #262626;
  box-shadow: 0 0 vw(41px) rgba(0, 0, 0, 0.16);
  padding: vw(94px) vw(76px) vw(71px) vw(75px);
  //margin-left: vw(124px);
}

.contact-form-outer-div {
  padding: vw(37px) vw(35px) vw(76px) vw(156px);
  position: relative;
  z-index: 99;
  margin-left: vw(124px);
}
.contact-form-small-square-div {
  position: absolute;
  right: 0;
  top: 0;
}
.contact-form-small-square-img {
  width: vw(74px);
  height: auto;
}
.contact-form-big-square-div {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}
.contact-form-big-square-img {
  width: vw(409px);
  height: auto;
}

.contact-input-div {
  //width: vw(778px);
  textarea,
  input {
    width: 100%;
    background: transparent;
    padding: vw(5px) vw(20px) vw(13px) vw(5px);
    border: none;
    border-bottom: 1px solid #ffffff;
    font-size: vw(27px);
    line-height: vw(38.61px);
    color: #ffffff;
    @include fontCeraBold;
    //@include fontBrandonGrotesqueBold;

    &::placeholder {
      font-size: vw(27px);
      line-height: vw(38.61px);
      color: #ffffff;
      @include fontCreaMedium;
      //@include fontBrandonGrotesqueLight;
    }
  }
}
.contact-input-div--textarea {
  textarea {
    height: vw(150px);
  }
}
.contact-submit-btn {
  width: vw(255px);
  height: vw(71px);
  background: #3d70f1;
  border-radius: vw(4px);
  margin-top: vw(42px);
  font-size: vw(24px);
  line-height: vw(30.36px);
  color: #ffffff;
  @include fontCircularStd;
}
.contact-arrow-img {
  width: vw(59px);
}
