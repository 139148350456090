/* ===============================================================
                    
                    typography Nunito

================================================================== */

/* ===============================================================
                            circular
================================================================== */
@font-face {
  font-family: Circular;
  src: url("../fonts/CircularStd/circularstd-book.otf");
}

/* ===============================================================
                           Brandon Grotesque
================================================================== */
@font-face {
  font-family: brandon-grotesque-bold;
  src: url("../fonts/Brandon-Grotesque-Font-Family/FontsFree-Net-d4.ttf");
}
@font-face {
  font-family: brandon-grotesque-light;
  src: url("../fonts/Brandon-Grotesque-Font-Family/FontsFree-Net-d1.ttf");
}
/* ===============================================================
                           Cera
================================================================== */
@font-face {
  font-family: cera-medium;
  src: url("../fonts/cerapro/Cera\ Pro\ Medium.otf");
}
@font-face {
  font-family: cera-bold;
  src: url("../fonts/cerapro/Cera\ Pro\ Bold.otf");
}
